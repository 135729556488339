import React from "react";
import { Fab, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import MessageList from "./message-list";
import {
  toggleChatWindow,
  closeTwilioChat,
  sendTwilioChatMsg,
  leaveTwilioChat
} from "actions/twilio-chat";
import SendMsgInput from "./send-msg-input";
import { Remove, QuestionAnswer, Chat } from "@mui/icons-material";
import ChatIcon from "assets/images/chat.png";
import "./twilio-chat.scss";
import useTranslate from "hooks/use-translate-language";

const TwilioChatWindow = () => {
  const translate = useTranslate();
  const chatActive = useSelector(selectors.getTwilioChatActive);
  const chatWindowOpen = useSelector(selectors.getChatIsOpen);
  const chatNotify = useSelector(state => state.twilioChat.notify);
  const isConnected = useSelector(selectors.getChatIsConnected);
  const dispatch = useDispatch();
  const messages = useSelector(selectors.getChatMessages);

  React.useEffect(() => {
    if (chatActive && chatNotify && !chatWindowOpen) {
      document.title = "New Message!";
    }
    return () => {
      document.title = "My Sleep Coach";
    };
  }, [chatActive, chatNotify, chatWindowOpen]);
  if (!chatActive) return null;
  return (
    <>
      {!chatWindowOpen && (
        <Fab
          variant="extended"
          style={{
            position: "fixed",
            bottom: "23px",
            right: "28px"
          }}
          onClick={() => {
            dispatch(toggleChatWindow(true));
          }}
          className={`messaging-fab ${chatNotify ? "alert" : ""}`}
        >
          <QuestionAnswer />
          {/*chatNotify && <div className="notify alert" />*/}
        </Fab>
      )}
      <div
        className="chat-window"
        style={{ display: chatWindowOpen ? "block" : "none" }}
      >
        <div className="chat-window-container">
          <div className="chat-window-header">
            <Chat fontSize="small" />
            <span>{translate("textChat")}</span>
            <Button
              size="small"
              onClick={() => {
                dispatch(toggleChatWindow(false));
              }}
              aria-controls="chat-minimize"
              aria-label="minimize"
              aria-haspopup="true"
              style={{
                width: "20px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Remove />
            </Button>
          </div>
          <div className="chat-window-content">
            {messages.length <= 0 ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <img
                  src={ChatIcon}
                  alt=""
                  style={{
                    width: 56,
                    height: 56,
                    alignSelf: "center",
                    display: "flex"
                  }}
                />
                <h5
                  style={{
                    margin: 5,
                    paddingBottom: 45,
                    fontSize: 16,
                    color: "#24ABFB",
                    textAlign: "center",
                    fontFamily: "Poppins-Regular"
                  }}
                >
                  {translate("willBeWithYouShortly")}
                </h5>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    dispatch(closeTwilioChat());
                  }}
                >
                  {translate("cancel")}
                </Button>
              </div>
            ) : (
              <div style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
                <div style={{ maxHeight: "80vh", overflow: "auto" }}>
                  <MessageList messages={messages} />
                </div>
                {isConnected ? (
                  <SendMsgInput
                    sendMessage={msg => dispatch(sendTwilioChatMsg(msg))}
                    leaveTwilioChat={() => dispatch(leaveTwilioChat())}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dispatch(closeTwilioChat());
                      }}
                      style={{ width: "100%" }}
                    >
                      {translate("close")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TwilioChatWindow;
